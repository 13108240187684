import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Frequently Asked Questions',
  header: 'Frequently Asked Questions',
  description: 'Uploadcare FAQ: this article incorporates the answers to the questions we’re asked most frequently. If you won’t find the right answer here, refer to our Knowledge Base or start an online chat.'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "where-are-my-api-keys"
    }}><a parentName="h2" {...{
        "href": "#where-are-my-api-keys"
      }}>{`Where are my API keys?`}</a></h2>
    <p>{`You can find and manage your project’s API keys on project’s overview page. Open
`}<a parentName="p" {...{
        "href": "/dashboard/"
      }}>{`dashboard`}</a>{`, if you have no projects, create one. If you already
have project click on it’s name and find “Keys” section.`}</p>
    <h2 {...{
      "id": "is-free-plan-free-forever"
    }}><a parentName="h2" {...{
        "href": "#is-free-plan-free-forever"
      }}>{`Is free plan free forever?`}</a></h2>
    <p>{`Absolutely. You get what you have signed up for. If at some moment in future we
change our pricing plans, this will affect only new customers.`}</p>
    <h2 {...{
      "id": "is-there-a-file-size-limit"
    }}><a parentName="h2" {...{
        "href": "#is-there-a-file-size-limit"
      }}>{`Is there a file size limit?`}</a></h2>
    <p>{`File size limit is determined by your `}<a parentName="p" {...{
        "href": "/pricing/"
      }}>{`pricing`}</a>{` plan, starting from
100`}{` `}{`MB and up to 5`}{` `}{`GB. We support uploads of very large (up to
5`}{` `}{`TB) files too, so if you need to be able to handle files larger than
5`}{` `}{`GB — `}<a className="js-support-link" href="mailto:help@uploadcare.com">{`contact
us`}</a>{`. If you want to reduce this limit for widget (e.g. allow only files less
then 1`}{` `}{`MB), you can use `}<a parentName="p" {...{
        "href": "/docs/api_reference/javascript/file_validation/"
      }}>{`custom
validation`}</a>{`.`}</p>
    <h2 {...{
      "id": "can-my-users-upload-video-or-audio-files"
    }}><a parentName="h2" {...{
        "href": "#can-my-users-upload-video-or-audio-files"
      }}>{`Can my users upload video or audio files?`}</a></h2>
    <p>{`Uploadcare will handle all file types: images, videos, audio, documents,
archives, memory dumps and what have you.`}</p>
    <h2 {...{
      "id": "does-your-widget-support-localization"
    }}><a parentName="h2" {...{
        "href": "#does-your-widget-support-localization"
      }}>{`Does your widget support localization?`}</a></h2>
    <p>{`Yes. We already support several locales and you can use `}<a parentName="p" {...{
        "href": "/docs/file_uploads/widget/translations/#add-locale"
      }}>{`your
own`}</a>{`. Also you can help us add new and improve existing
localizations.`}</p>
    <h2 {...{
      "id": "do-you-support-customization"
    }}><a parentName="h2" {...{
        "href": "#do-you-support-customization"
      }}>{`Do you support customization?`}</a></h2>
    <p><a parentName="p" {...{
        "href": "/docs/file_uploads/widget/widget_styling/"
      }}>{`Yes`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      